@import '../../../../scss/_theme-bootstrap';

.gnav-utility-item {
  position: relative;
  &__trigger-label {
    @include typography-link-text;
    font-size: $nav-label-font-size;
    color: $color-core-black;
    text-decoration: none;
  }
  &__overlay {
    position: static;
    &-content {
      @include transition(opacity 0.4s, visibility 0.4s);
      position: absolute;
      #{$rdirection}: 0;
      display: none;
      z-index: 999;
      top: 19px;
      height: auto;
      line-height: normal;
      transform: translate(5%, 0);
      .gnav-utility-item--active & {
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
    }
    &-close {
      position: absolute;
      #{$rdirection}: 10px;
      z-index: 10;
      top: 15px;
      cursor: pointer;
      overflow: hidden;
      .icon--close {
        height: 21px;
        width: 20px;
        fill: $color-gray-alt;
      }
    }
    &-content-arrow {
      position: absolute;
      top: 5px;
      width: 25px;
      height: 12px;
      background: url('https://www.bobbibrowncosmetics.ca/media/images/global/util-dropdown-arrow.png') no-repeat 0 0;
      z-index: 10;
      #{$rdirection}: 30px;
    }
    &-content-wrapper {
      position: relative;
      margin-top: 16px;
      border: 1px solid $color-gray-alt;
      background: $color-white;
      width: auto;
      display: inline-block;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      margin-#{$rdirection}: -1px;
      .sign-in {
        max-height: 425px;
        @include breakpoint($tall-up) {
          max-height: 530px;
        }
        .registration__submit {
          margin-top: 20px;
        }
      }
    }
  }
}
